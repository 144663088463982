var $ = require("jquery");

$(document).ready(function () {

    let $minimenu = $("#minimenu");

    let $langBtn = $("#langButton");
    let $searchBtn = $("#searchButton");

    let $lang = $("#lang");
    let $search = $("#search");

    let xs = '94px';
    let md = '94px';
  let xs2 = '144px';
  let md2 = '94px';

    $langBtn.click(function () {
        $langHeight = $lang.outerHeight(true);
        if ($langHeight < 1) {
            toggleAnimation($lang);
            growMenu();
        } else {
            toggleAnimation($lang);
            shrinkMenu();
        }
    })

    $searchBtn.click(function () {
        $searchHeight = $search.outerHeight(true);
        if ($searchHeight < 1) {
            toggleAnimation($search);
            growMenu2();
        } else {
            toggleAnimation($search);
            shrinkMenu2();
        }
    })

    function toggleAnimation(el) {
        if (el.hasClass("animate")) {
            el.removeClass("animate")
            el.addClass("deanimate")
        } else {
            el.removeClass("deanimate")
            el.addClass("animate")
        }
    }

    function shrinkMenu() {
        if ($(window).width() < 768) {
          console.log($search.outerHeight(true), $lang.outerHeight(true))
            $minimenu.css("padding-top", "-=" + xs);
        } else {
            $minimenu.css("padding-top", "-=" + md);
        }
    }

    function growMenu() {
        if ($(window).width() < 768) {
            $minimenu.css("padding-top", "+=" + xs);
        } else {
            $minimenu.css("padding-top", "+=" + md);
        }
    }
  function shrinkMenu2() {
    if ($(window).width() < 768) {
      console.log($search.outerHeight(true), $lang.outerHeight(true))
      $minimenu.css("padding-top", "-=" + xs2);
    } else {
      $minimenu.css("padding-top", "-=" + md2);
    }
  }

  function growMenu2() {
    if ($(window).width() < 768) {
      $minimenu.css("padding-top", "+=" + xs2);
    } else {
      $minimenu.css("padding-top", "+=" + md2);
    }
  }
});

// Load Bootstrap JS
import bootstrap from 'bootstrap'

// Load Styles
import '../scss/main.scss';

// Load libs
import 'jquery';

// App code
import './swiper'
import './video'
import './overlay'
import './flaschecontainerV2'
import './accordion'
import './isotope'
import './news'
import './minimenu'
// import './map'
// import './features-2cols'
import './form'
